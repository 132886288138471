import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { useKeycloak } from '@react-keycloak/web';

import ToastCustom from '../../components/Toast';

import PlardTable from '../../components/PlardPage/PlardTable';
import PlardFilter from '../../components/PlardPage/PlardFilter';
import Pag from '../../components/Nucleo/Content/CustomTable/Pag';

import { Container, PlardHeaderContentStyle } from './styles';

import {
  handleGetRequest,
  initialPaginationData,
} from '../../services/GenericMethods';

import {
  formatErrorMessageToToast,
  populateToastMessagesGlobal,
} from '../../helpers/GlobalFunctions';

export default function Plard({ userPermissions }) {
  const [currentFilter, setCurrentFilter] = useState([]);
  const [currentPageUsers, setcurrentPageUsers] = useState(0);
  const [totalPagesUsers, setTotalPagesUsers] = useState(0);
  const [isFirstPageUsers, setisFirstPageUsers] = useState(false);
  const [isLastPageUsers, setisLastPageUsers] = useState(false);
  const [currentPageProducts, setcurrentPageProducts] = useState(0);
  const [totalPagesProducts, setTotalPagesProducts] = useState(0);
  const [isFirstPageProducts, setisFirstPageProducts] = useState(false);
  const [isLastPageProducts, setisLastPageProducts] = useState(false);
  const [currentPageEvents, setcurrentPageEvents] = useState(0);
  const [totalPagesEvents, setTotalPagesEvents] = useState(0);
  const [isFirstPageEvents, setisFirstPageEvents] = useState(false);
  const [isLastPageEvents, setisLastPageEvents] = useState(false);
  const [currentPageLoyalties, setcurrentPageLoyalties] = useState(0);
  const [totalPagesLoyalties, setTotalPagesLoyalties] = useState(0);
  const [isFirstPageLoyalties, setisFirstPageLoyalties] = useState(false);
  const [isLastPageLoyalties, setisLastPageLoyalties] = useState(false);

  const [plardUsers, setPlardUsers] = useState([]);
  const [plardProducts, setPlardProducts] = useState([]);
  const [plardLoyalties, setPlardLoyalties] = useState([]);
  const [plardEvents, setPlardEvents] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [mainToastList, setMainToastList] = useState([]);
  const { keycloak } = useKeycloak();
  const options = initialPaginationData(
    'GET',
    currentPageUsers,
    keycloak.token,
  );
  const FULL_USERS_URL =
    window.SERVICES_DOMAIN + window.AUDIT_PLARD_USERS_GET_URL;
  const FULL_PRODUCTS_URL =
    window.SERVICES_DOMAIN + window.AUDIT_PLARD_PRODUCTS_GET_URL;
  const FULL_EVENTS_URL =
    window.SERVICES_DOMAIN + window.AUDIT_PLARD_EVENTS_GET_URL;
  const FULL_LOYALTIES_URL =
    window.SERVICES_DOMAIN + window.AUDIT_PLARD_LOYALTIES_GET_URL;

  const getPlardUsers = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_USERS_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setPlardUsers(content);

            setcurrentPageUsers(response.data.number);
            setTotalPagesUsers(response.data.totalPages);
            setisFirstPageUsers(response.data.first);
            setisLastPageUsers(response.data.last);
          } else {
            setcurrentPageUsers(0);
            setTotalPagesUsers(0);
            setisFirstPageUsers(false);
            setisLastPageUsers(false);
            setPlardUsers([]);
          }
        })
        .catch(error => {
          console.error(`Error at getPlardUsers request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const getPlardProducts = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_PRODUCTS_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setPlardProducts(content);

            setcurrentPageProducts(response.data.number);
            setTotalPagesProducts(response.data.totalPages);
            setisFirstPageProducts(response.data.first);
            setisLastPageProducts(response.data.last);
          } else {
            setcurrentPageProducts(0);
            setTotalPagesProducts(0);
            setisFirstPageProducts(false);
            setisLastPageProducts(false);
            setPlardProducts([]);
          }
        })
        .catch(error => {
          console.error(`Error at getPlardProducts request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const getPlardLoyalties = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_LOYALTIES_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setPlardLoyalties(content);

            setcurrentPageLoyalties(response.data.number);
            setTotalPagesLoyalties(response.data.totalPages);
            setisFirstPageLoyalties(response.data.first);
            setisLastPageLoyalties(response.data.last);
          } else {
            setcurrentPageLoyalties(0);
            setTotalPagesLoyalties(0);
            setisFirstPageLoyalties(false);
            setisLastPageLoyalties(false);
            setPlardLoyalties([]);
          }
        })
        .catch(error => {
          console.error(`Error at getPlardLoyalties request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const getPlardEvents = inputParams => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      )
    ) {
      handleGetRequest(FULL_EVENTS_URL, inputParams)
        .then(response => {
          if (
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            const { content } = response.data;
            setPlardEvents(content);

            setcurrentPageEvents(response.data.number);
            setTotalPagesEvents(response.data.totalPages);
            setisFirstPageEvents(response.data.first);
            setisLastPageEvents(response.data.last);
          } else {
            setcurrentPageEvents(0);
            setTotalPagesEvents(0);
            setisFirstPageEvents(false);
            setisLastPageEvents(false);
            setPlardEvents([]);
          }
        })
        .catch(error => {
          console.error(`Error at getPlardLoyalties request = ${error}`);
          const errorMessage = formatErrorMessageToToast(error, keycloak);
          const appendToast = populateToastMessagesGlobal(
            'error',
            'Erro',
            errorMessage.messagePTBR,
            'main',
          );
          setMainToastList([...toastList, appendToast]);
        });
    }
  };

  const handleShowMessageAction = (status, title, message) => {
    if (!!status && !!title && !!message) {
      const appendToast = populateToastMessagesGlobal(
        status,
        title,
        message,
        'secundary',
      );
      setToastList([...toastList, appendToast]);
    }
  };

  const handlePageChangeUsers = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getPlardUsers(options);
  };

  const handlePageChangeLoyalties = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getPlardLoyalties(options);
  };

  const handlePageChangeEvents = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getPlardEvents(options);
  };

  const handlePageChangeProducts = page => {
    const filter = currentFilter;
    filter.pageNumber = page;
    options.params = filter;
    setCurrentFilter(filter);
    getPlardProducts(options);
  };

  const handleFilter = inputParams => {
    options.params = inputParams;
    setCurrentFilter(inputParams);
    getPlardUsers(options);
    getPlardProducts(options);
    getPlardLoyalties(options);
    getPlardEvents(options);
  };

  const setOptionParams = () => {
    if (currentFilter.length !== 0) {
      options.params = currentFilter;
    }
  };

  useEffect(() => {
    setOptionParams();
    if (!options.params.dateStart) {
      options.params.dateStart = moment().format('YYYY-MM-DD');
    }
    if (!options.params.dateEnd) {
      options.params.dateEnd = moment().format('YYYY-MM-DD');
    }
    setCurrentFilter(options.params);
    getPlardUsers(options);
    getPlardProducts(options);
    getPlardLoyalties(options);
    getPlardEvents(options);
  }, [toastList]);

  return (
    <Container>
      <ToastCustom
        toastList={toastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      <ToastCustom
        toastList={mainToastList}
        position="bottom-right"
        autoDelete
        dismissTime={5000}
      />
      <PlardHeaderContentStyle>
        <header className="contentmain__header">
          <div className="contentmain__header__title">
            <text className="contentmain__header__text contentmain__header__text__active">
              Plard
            </text>
          </div>
        </header>
      </PlardHeaderContentStyle>
      <PlardFilter currentFilter={currentFilter} handleFilter={handleFilter} />

      <h4>Usuários</h4>
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {plardUsers.length > 0 ? (
            <PlardTable
              items={plardUsers}
              type="user"
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPagesUsers > 0 ? (
        <Pag
          currentPage={currentPageUsers}
          totalPages={totalPagesUsers}
          isFirstPage={isFirstPageUsers}
          isLastPage={isLastPageUsers}
          onClickFnPage={handlePageChangeUsers}
        />
      ) : null}

      <h4>Produtos</h4>
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {plardProducts.length > 0 ? (
            <PlardTable
              items={plardProducts}
              type="product"
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPagesProducts > 0 ? (
        <Pag
          currentPage={currentPageProducts}
          totalPages={totalPagesProducts}
          isFirstPage={isFirstPageProducts}
          isLastPage={isLastPageProducts}
          onClickFnPage={handlePageChangeProducts}
        />
      ) : null}

      <h4>Loyalties</h4>
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {plardLoyalties.length > 0 ? (
            <PlardTable
              items={plardLoyalties}
              type="loyalty"
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPagesLoyalties > 0 ? (
        <Pag
          currentPage={currentPageLoyalties}
          totalPages={totalPagesLoyalties}
          isFirstPage={isFirstPageLoyalties}
          isLastPage={isLastPageLoyalties}
          onClickFnPage={handlePageChangeLoyalties}
        />
      ) : null}

      <h4>Eventos</h4>
      {userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      ) ? (
        <section className="mt-2">
          {plardEvents.length > 0 ? (
            <PlardTable
              items={plardEvents}
              type="event"
              userPermissions={userPermissions}
              handleShowMessageAction={handleShowMessageAction}
            />
          ) : (
            <div>Nenhum resultado encontrado para esta pesquisa</div>
          )}
        </section>
      ) : null}

      {totalPagesEvents > 0 ? (
        <Pag
          currentPage={currentPageEvents}
          totalPages={totalPagesEvents}
          isFirstPage={isFirstPageEvents}
          isLastPage={isLastPageEvents}
          onClickFnPage={handlePageChangeEvents}
        />
      ) : null}
    </Container>
  );
}
