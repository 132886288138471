import React, { useState } from 'react';

import moment from 'moment';

import Button from '../../Button';
import constants from '../../../config';

import { StyledForm } from './styles';

export default function PlardFilter({ currentFilter, handleFilter }) {
  const [status, setStatus] = useState('DEFAULT');
  const [customerId, setCustomerId] = useState(null);
  const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));
  const [proposalId, setProposalId] = useState(null);

  const handleStatuslist = e => {
    setStatus(e.target.value);
  };

  const handleCustomerId = e => {
    setCustomerId(e.target.value);
  };

  const handleProposalId = e => {
    setProposalId(e.target.value);
  };

  const handleDateStart = e => {
    setDateStart(e.target.value);
  };

  const handleDateEnd = e => {
    setDateEnd(e.target.value);
  };

  const clearFilter = () => {
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.status;
    delete filter.customerId;
    delete filter.dateStart;
    delete filter.dateEnd;
    delete filter.proposalId;

    setStatus('DEFAULT');
    setCustomerId('');
    setProposalId('');
    setDateStart(moment().format('YYYY-MM-DD'));
    setDateEnd(moment().format('YYYY-MM-DD'));

    filter.dateStart = moment().format('YYYY-MM-DD');
    filter.dateEnd = moment().format('YYYY-MM-DD');

    handleFilter(filter);
  };

  const applyFilter = e => {
    e.preventDefault();
    const filter = currentFilter;
    filter.pageNumber = 0;

    delete filter.status;
    delete filter.customerId;
    delete filter.dateStart;
    delete filter.dateEnd;
    delete filter.proposalId;

    if (status !== 'DEFAULT') filter.status = status;
    if (customerId) filter.customerId = customerId;
    if (proposalId) filter.proposalId = proposalId;
    if (dateStart) filter.dateStart = dateStart;
    if (dateEnd) filter.dateEnd = dateEnd;

    handleFilter(filter);
  };

  const isFilterValid = () => {
    const isValid =
      status !== 'DEFAULT' || customerId || proposalId || dateStart || dateEnd;
    return isValid;
  };

  return (
    <StyledForm>
      <div className="form-content">
        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Status:</StyledForm.Label>
            <StyledForm.Control
              type="select"
              as="select"
              value={status || ''}
              onChange={handleStatuslist}
            >
              <option disabled value="DEFAULT">
                Filtre por status
              </option>
              {Object.keys(constants.PLARD_STATUS).map(item => (
                <option value={item} key={item}>
                  {constants.PLARD_STATUS[item]}
                </option>
              ))}
            </StyledForm.Control>
          </StyledForm.Group>
        </div>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Cpf/Cnpj:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleCustomerId}
              type="text"
              value={customerId || ''}
            />
          </StyledForm.Group>
        </div>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Id Plard:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleProposalId}
              type="text"
              value={proposalId || ''}
            />
          </StyledForm.Group>
        </div>

        <div className="inputs-group">
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Inicio:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleDateStart}
              type="date"
              value={dateStart || ''}
            />
          </StyledForm.Group>
          <StyledForm.Group className="form-group">
            <StyledForm.Label>Fim:</StyledForm.Label>
            <StyledForm.Control
              onChange={handleDateEnd}
              type="date"
              value={dateEnd || ''}
            />
          </StyledForm.Group>
        </div>
      </div>

      <div className="form-actions">
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={applyFilter}
          disabled={!isFilterValid()}
        >
          Aplicar Filtros
        </Button>
        <button onClick={clearFilter} type="button" className="btn-link">
          Limpar Filtros
        </button>
      </div>
    </StyledForm>
  );
}
