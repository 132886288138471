import React, { useState, useEffect } from 'react';

import Loading from '../../Loading';
import { StyledModal, Form } from './styles';

export default function ModalPlardHistory({
  item,
  type,
  isOpenModal,
  handleCloseModal,
  userPermissions,
}) {
  const [showLoading, setShowLoading] = useState(false);

  const getPlardHistory = () => {
    if (
      userPermissions.checkUiPermissionsFn(
        'ESFERA.PLARD.READ',
        userPermissions.userGroups,
      )
    ) {
      setShowLoading(true);
      setShowLoading(false);
    }
  };

  useEffect(() => {
    getPlardHistory();
  }, [item.id]);

  return (
    <StyledModal show={isOpenModal} onHide={handleCloseModal} centered>
      <Loading showLoading={showLoading} />

      <StyledModal.Header closeButton>
        {type === 'user' && (
          <>
            <StyledModal.Title>
              Detalhes do processamento de usuários
            </StyledModal.Title>
          </>
        )}
        {type === 'loyalty' && (
          <>
            <StyledModal.Title>
              Detalhes do processamento de loyalties
            </StyledModal.Title>
          </>
        )}
        {type === 'event' && (
          <>
            <StyledModal.Title>
              Detalhes do processamento de eventos
            </StyledModal.Title>
          </>
        )}
        {type === 'product' && (
          <>
            <StyledModal.Title>
              Detalhes do processamento de produtos
            </StyledModal.Title>
          </>
        )}
      </StyledModal.Header>

      <StyledModal.Body>
        {type === 'user' && (
          <>
            <Form>
              <Form.Group>
                <Form.Label>Id:</Form.Label>
                <Form.Control type="text" value={item.idUser} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Id da Plard:</Form.Label>
                <Form.Control type="text" value={item.idproposal} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Cpf/Cnpj:</Form.Label>
                <Form.Control type="text" value={item.idCustomer} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de criação:</Form.Label>
                <Form.Control type="text" value={item.dtCreated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de atualização:</Form.Label>
                <Form.Control type="text" value={item.dtUpdated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status:</Form.Label>
                <Form.Control type="text" value={item.dsStatus} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Nome do usuário:</Form.Label>
                <Form.Control type="text" value={item.nmUser} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control type="text" value={item.dsEmail} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Plard:</Form.Label>
                <Form.Control as="textarea" value={item.jsUserData} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Comarch request:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.jsComarchRequest}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Comarch response:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.txComarchResponse}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status Code comarch:</Form.Label>
                <Form.Control
                  type="text"
                  value={item.nrComarchStatus}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Retentantivas de processamento:</Form.Label>
                <Form.Control
                  type="text"
                  value={item.nrComarchRetries}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Id do evento:</Form.Label>
                <Form.Control type="text" value={item.idRecord} readOnly />
              </Form.Group>
            </Form>
          </>
        )}
        {type === 'loyalty' && (
          <>
            <Form>
              <Form.Group>
                <Form.Label>Id:</Form.Label>
                <Form.Control type="text" value={item.idLoyalty} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Id da Plard:</Form.Label>
                <Form.Control type="text" value={item.idproposal} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Cpf/Cnpj:</Form.Label>
                <Form.Control type="text" value={item.idCustomer} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de criação:</Form.Label>
                <Form.Control type="text" value={item.dtCreated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de atualização:</Form.Label>
                <Form.Control type="text" value={item.dtUpdated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status:</Form.Label>
                <Form.Control type="text" value={item.dsStatus} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Plard:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.jsLoyaltyData}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Comarch request:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.jsComarchRequest}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Comarch response:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.txComarchResponse}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status Code comarch:</Form.Label>
                <Form.Control
                  type="text"
                  value={item.nrComarchStatus}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Retentantivas de processamento:</Form.Label>
                <Form.Control
                  type="text"
                  value={item.nrComarchRetries}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Id do evento:</Form.Label>
                <Form.Control type="text" value={item.idRecord} readOnly />
              </Form.Group>
            </Form>
          </>
        )}
        {type === 'product' && (
          <>
            <Form>
              <Form.Group>
                <Form.Label>Id:</Form.Label>
                <Form.Control type="text" value={item.idProduct} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Id da Plard:</Form.Label>
                <Form.Control type="text" value={item.idproposal} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Cpf/Cnpj:</Form.Label>
                <Form.Control type="text" value={item.idCustomer} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de criação:</Form.Label>
                <Form.Control type="text" value={item.dtCreated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de atualização:</Form.Label>
                <Form.Control type="text" value={item.dtUpdated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status:</Form.Label>
                <Form.Control type="text" value={item.dsStatus} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Nome do produto:</Form.Label>
                <Form.Control type="text" value={item.nmProduct} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control type="text" value={item.nmEmail} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Plard:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.jsProductData}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Comarch request:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.jsComarchRequest}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Comarch response:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.txComarchResponse}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status Code comarch:</Form.Label>
                <Form.Control
                  type="text"
                  value={item.nrComarchStatus}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Retentantivas de processamento:</Form.Label>
                <Form.Control
                  type="text"
                  value={item.nrComarchRetries}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Id do evento:</Form.Label>
                <Form.Control type="text" value={item.idRecord} readOnly />
              </Form.Group>
            </Form>
          </>
        )}
        {type === 'event' && (
          <>
            <Form>
              <Form.Group>
                <Form.Label>Id:</Form.Label>
                <Form.Control type="text" value={item.idRecord} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Id da Plard:</Form.Label>
                <Form.Control type="text" value={item.idproposal} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Cpf/Cnpj:</Form.Label>
                <Form.Control type="text" value={item.idCustomer} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de criação:</Form.Label>
                <Form.Control type="text" value={item.dtCreated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Data de atualização:</Form.Label>
                <Form.Control type="text" value={item.dtUpdated} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Status:</Form.Label>
                <Form.Control type="text" value={item.dsStatus} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tipo de evento:</Form.Label>
                <Form.Control type="text" value={item.dsEventType} readOnly />
              </Form.Group>
              <Form.Group>
                <Form.Label>Json Plard:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={item.jsAdditionalData}
                  readOnly
                />
              </Form.Group>
            </Form>
          </>
        )}
      </StyledModal.Body>
    </StyledModal>
  );
}
