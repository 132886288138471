import React from 'react';

import PlardItem from '../PlardItem';
import { StyledTable } from './styles';

export default function PlardTable({
  items,
  type,
  userPermissions,
  handleShowMessageAction,
}) {
  return (
    <StyledTable responsive>
      <thead>
        <tr>
          {type === 'user' && (
            <>
              <th>Id</th>
              <th>id PlardA</th>
              <th>Cpf/Cnpj</th>
              <th>Data</th>
              <th>Status</th>
              <th>Detalhes</th>
            </>
          )}
          {type === 'product' && (
            <>
              <th>Id</th>
              <th>id PlardD</th>
              <th>Cpf/Cnpj</th>
              <th>Data</th>
              <th>Status</th>
              <th>Detalhes</th>
            </>
          )}
          {type === 'event' && (
            <>
              <th>Id</th>
              <th>id PlardE</th>
              <th>Cpf/Cnpj</th>
              <th>Data</th>
              <th>Status</th>
              <th>Detalhes</th>
            </>
          )}
          {type === 'loyalty' && (
            <>
              <th>Id</th>
              <th>id Plard</th>
              <th>Cpf/Cnpj</th>
              <th>Data</th>
              <th>Status</th>
              <th>Detalhes</th>
            </>
          )}
        </tr>
      </thead>

      <tbody>
        {items.map(item => (
          <PlardItem
            item={item}
            type={type}
            key={Math.random()}
            userPermissions={userPermissions}
            handleShowMessageAction={handleShowMessageAction}
          />
        ))}
      </tbody>
    </StyledTable>
  );
}
