import React, { useState } from 'react';
import { TbEye } from 'react-icons/tb';
import ModalPlardHistory from '../ModalPlardHistory';
import constants from '../../../config';

import { StyledTr } from './styles';

export default function PlardItem({
  item,
  type,
  userPermissions,
  handleShowMessageAction,
}) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);

  const handleCloseMdlModalHistory = (status, title, message) => {
    handleShowMessageAction(status, title, message);
    setIsOpenModalHistory(false);
  };

  const handleShowMdlModalHistory = itemPlard => {
    setSelectedItem(itemPlard);
    setIsOpenModalHistory(true);
  };

  return (
    <>
      <StyledTr>
        {type === 'user' && (
          <>
            <td>{item.idUser}</td>
            <td>{item.idproposal}</td>
            <td>{item.idCustomer}</td>
            <td>{item.dtCreated}</td>
            <td>{constants.PLARD_STATUS[item.dsStatus]}</td>
            <td className="td-center ">
              <button
                className="btn-history"
                type="button"
                onClick={() => handleShowMdlModalHistory(item)}
                aria-label="Ver detalhes"
              >
                <TbEye />
              </button>
            </td>
          </>
        )}
        {type === 'product' && (
          <>
            <td>{item.idProduct}</td>
            <td>{item.idproposal}</td>
            <td>{item.idCustomer}</td>
            <td>{item.dtCreated}</td>
            <td>{constants.PLARD_STATUS[item.dsStatus]}</td>
            <td className="td-center ">
              <button
                className="btn-history"
                type="button"
                onClick={() => handleShowMdlModalHistory(item)}
                aria-label="Ver detalhes"
              >
                <TbEye />
              </button>
            </td>
          </>
        )}
        {type === 'loyalty' && (
          <>
            <td>{item.idLoyalty}</td>
            <td>{item.idproposal}</td>
            <td>{item.idCustomer}</td>
            <td>{item.dtCreated}</td>
            <td>{constants.PLARD_STATUS[item.dsStatus]}</td>
            <td className="td-center ">
              <button
                className="btn-history"
                type="button"
                onClick={() => handleShowMdlModalHistory(item)}
                aria-label="Ver detalhes"
              >
                <TbEye />
              </button>
            </td>
          </>
        )}
        {type === 'event' && (
          <>
            <td>{item.idRecord}</td>
            <td>{item.idproposal}</td>
            <td>{item.idCustomer}</td>
            <td>{item.dtCreated}</td>
            <td>{constants.PLARD_STATUS[item.dsStatus]}</td>
            <td className="td-center ">
              <button
                className="btn-history"
                type="button"
                onClick={() => handleShowMdlModalHistory(item)}
                aria-label="Ver detalhes"
              >
                <TbEye />
              </button>
            </td>
          </>
        )}
      </StyledTr>

      {isOpenModalHistory && (
        <ModalPlardHistory
          item={selectedItem}
          type={type}
          isOpenModal={isOpenModalHistory}
          handleCloseModal={handleCloseMdlModalHistory}
          userPermissions={userPermissions}
        />
      )}
    </>
  );
}
